<!-- -设备管理-备件台账-备件盘点-详情 -->
<template>
    <div class="page_container">
        <inner-top-nav></inner-top-nav>
        <div class="bgc_FFFFFF flex flex_column align_center">
            <div class="box_head1 width_100">盘点信息</div>
            <el-form :inline="true" :model="formInline" label-width="110px" class="mg_top_30 flex flex_wrap "
                style="width: 65%;">
                <el-form-item label="工单编号：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

                <el-form-item label="当前审批顺序：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

                <el-form-item label="是否审批：" class=" ">
                    <el-radio-group v-model="formInline.isAuth" class="width_180">
                        <el-radio :label="true" disabled>是</el-radio>
                        <el-radio :label="false" disabled>否</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="记录时间：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

                <el-form-item label="记录人：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

                <el-form-item label="仓库：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

                <el-form-item label="工单状态：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item>

             </el-form>
            <el-form :model="formInline" label-width="110px" class="flex flex_wrap " style="width: 65%;">
                <el-form-item label="备注：">
                    <el-input v-model="formInline.remarks" class="width_1100" type="textarea" :rows="3" disabled>
                    </el-input>
                </el-form-item>
            </el-form>

                <!-- <el-form-item label="总页数：">
                    <el-input v-model="formInline.warehouse" placeholder="请输入" class="width_270 mg_right_50" disabled></el-input>
                </el-form-item> -->

            <div class="box_head1 width_100">备件信息</div>
            <!-- 按钮 -->
            <!-- <div class="flex mg_top_10" style="width: 65%;">
                <el-button type="primary" icon="el-icon-circle-plus-outline" @click="selectSparePart">选择备件</el-button>
                <el-button type="primary" icon="el-icon-circle-plus-outline">删除</el-button>
            </div> -->
            <!-- table -->
            <div class="height_300" style="width: 65%;">
                <el-table :data="tableData" tooltip-effect="dark" class="mg_top_15 " height="100%" border
                    style="width: 100%;">
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="insuredQuan" label="账面数量" show-overflow-tooltip>
                        <!-- <template slot-scope="scope">
                            读
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="realQuan" label="实际数量">
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan" @change="changeValue"></el-input>
                        </template> -->
                    </el-table-column>
                    <!-- 盘盈数量：realQuan>insuredQuan r-I；R<I： 0 -->
                    <el-table-column prop="profitQuan" label="盘盈数量">
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.profitQuan" readonly></el-input>
                        </template> -->
                    </el-table-column>
                    <!-- 盘亏数量  R>I 0  R<I： r-I -->
                    <el-table-column prop="lossQuan" label="盘亏数量">
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.lossQuan" readonly></el-input>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                        <!-- <template slot-scope="scope">
                            <el-input v-model="scope.row.remarks"></el-input>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pd_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delStandard(scope.$index)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <div class="flex mg_top_30 mg_bottom_30">
                <div>
                    <el-button type="primary" class="width_140" @click="sbumit">确定</el-button>
                </div>
                <el-button type="primary" class="width_140 mg_left_50" @click="colsePage">关闭</el-button>
            </div>
        </div>

        <el-dialog :visible.sync="sparePartDialog" top='15vh'>
            <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">选择备件台账</div>
            <div class="mg_top_10  fn_size14 color_1A1A1A height_500">
                <!-- <div v-for="(item,index) in inspectionStandardInfoList.inspDeviceContent" :key="index"> -->
                <!-- <div class="flex font_bold mg_top_10">
                        <span>点检标准编号：</span>
                        <span class="color_007DF2">{{item.inspStandardID}}</span>
                    </div> -->
                <el-table ref="multipleTable" :data="sparePartList" style="width: 100%;" height="100%" class="mg_top_10"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="70">
                    </el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="stockQuan" label="账面数量" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column prop="insuredQuan" label="insuredQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.insuredQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="realQuan" label="realQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.realQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="profitQuan" label="profitQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.profitQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lossQuan" label="lossQuan" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.lossQuan"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.remarks"></el-input>
                        </template>
                    </el-table-column> -->
                </el-table>

            </div>
            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            <div class="flex justify_center pdb_20 mg_top_20">
                <el-button @click="sparePartDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSparePart">确 定</el-button>
            </div>
        </el-dialog>



    </div>
</template>
<script>
    import innerTopNav from '../../../../components/innerTopNav'
    import {
        sparePartStockQueryLimit,
        sparePartInventoryInsert,
        sparePartInventoryDetailQuery,
        sparePartInventoryQuery
        // inspectionOrderUpdate,
    } from "@/api/api_deviceManagement";
    export default {
        components: {
            innerTopNav
        },
        data() {
            return {
                value1: '',
                radio1: 1,
                checked: false,
                tableData: [],
                formInline: {
                    user: '',
                    region: ''
                },
                input2: '',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                userID: '',
                sparePartDialog: false,
                sparePartList: []
            }
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                // console.log(userInfo)
                this.userID = userInfo.userID;
                this.userName = userInfo.userName;
            }
             if (localStorage.getItem('oderId')) {
                this.detailID = localStorage.getItem('oderId');
                this.sparePartInventoryQuery();
                // this.sparePartOutWarehouseDetailQuery();
            }
            
            // this.sparePartStockQueryLimit()
        },
        methods: {

            // 选择备件--弹窗--开始
            async sparePartStockQueryLimit() {
                var param = {
                    userID: this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                const res = await sparePartStockQueryLimit(param);
                this.sparePartList = res.rows;
                this.pageQuantity = res.pageQuantity;
            },
             async  sparePartInventoryQuery() {
                var param = {
                    // ID: 14,
                    ID: this.detailID
                }
                const res = await  sparePartInventoryQuery(param);
                this.formInline = res.rows[0];
                this.sparePartInventoryDetailQuery();
            },
            async sparePartInventoryDetailQuery() {
                var param = {
                    // ID: 14,
                    orderID:  this.formInline.orderID
                }
                const res = await sparePartInventoryDetailQuery(param);
                this.tableData = res.rows;
                // console.log(this.tableData)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                console.log(this.multipleSelection)
            },
            selectSparePart() {
                // this.$nextTick(() => {
                //     if (this.$refs['multipleTable']) {
                //         this.$refs['multipleTable'].clearSelection();
                //     }
                // });
                this.sparePartStockQueryLimit()
                this.sparePartDialog = true;
            },
            sureSparePart() {
                // console.log(this.sparePartList);
                // var testData = this.tableData.filter(itmer => itmer.sparePartID == this.currentRow.sparePartID);
                // // console.log(testData)
                // if (testData.length != 0) {
                //     this.$message({
                //         message: '警告，此设备已添加',
                //         type: 'warning'
                //     });
                //     return
                // }
                this.tableData = [];
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    var testData = {
                        sparePartID: '', //备件编号
                        sparePartName: '', //备件名称
                        sparePartModel: '', //备件型号
                        unit: '', //单位
                        insuredQuan: '', //
                        // "realQuan": 90,
                        // "profitQuan": 0,
                        // "lossQuan": 10,
                    }
                    testData.sparePartID = this.multipleSelection[i].sparePartID;
                    testData.sparePartName = this.multipleSelection[i].sparePartName;
                    testData.sparePartModel = this.multipleSelection[i].sparePartModel;
                    testData.unit = this.multipleSelection[i].unit;
                    testData.insuredQuan = this.multipleSelection[i].stockQuan;

                    this.tableData.push(testData)
                }
                this.sparePartDialog = false;
                this.multipleSelection = [];
                // sparePartID
            },
            handleCurrentChange(val) {
                this.sparePartStockQueryLimit(); //查询table数据的方法
            },
            //  // 选择备件--弹窗--结束

            changeValue(val) {
                console.log(val)
            },

            delStandard(index) {
                this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log(index)
                    this.tableData.splice(index, 1)
                    console.log(this.tableData);
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },


            async sbumit() {
                this.formInline.userID = this.userID;
                this.formInline.orderDetailJSON = {
                    "array": [{
                        "sparePartID": "aaa-1", //备件编号
                        "sparePartName": "aaa-1型备件", //备件名称
                        "sparePartModel": "aaa-1型", //备件型号
                        "unit": "件", //单位
                        "insuredQuan": 100, //读  账面数量
                        "realQuan": 90, //输入  实际数量
                        "profitQuan": 0, //盘盈数量：realQuan>insuredQuan r-I；R<I： 0
                        "lossQuan": 10, //盘亏数量  R>I 0  R<I： r-I
                        "remarks": "无备注"
                    }, {
                        "sparePartID": "aaa-2",
                        "sparePartName": "aaa-2型备件",
                        "sparePartModel": "aaa-2型",
                        "unit": "件",
                        "insuredQuan": 5,
                        "realQuan": 10,
                        "profitQuan": 5,
                        "lossQuan": 0,
                        "remarks": "无备注"
                    }, {
                        "sparePartID": "aaa-3",
                        "sparePartName": "aaa-3型备件",
                        "sparePartModel": "aaa-3型",
                        "unit": "件",
                        "insuredQuan": 15,
                        "realQuan": 20,
                        "profitQuan": 5,
                        "lossQuan": 0,
                        "remarks": "无备注"
                    }]
                }
                console.log(this.formInline)
                const selectRes = await sparePartInventoryInsert(this.formInline);

                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.$router.go(-1);
                } else {
                    this.$message.error(selectRes.message);
                }
                // sparePartInventoryInsert
                // userID
                //
            },
            colsePage() {
                this.$router.go(-1);
            },
            //table选择

        }

    }
</script>
<style scoped>

</style>
<style lang="less" scoped>
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 180px;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
        background: #03CABE;
        color: #FFFFFF;
        border: none;
    }

    ::v-deep .el-upload {
        width: 100%;
    }
</style>